import { amgApi } from "@/service/axios";

class chargesService {
  

  async getChargesCI(params) {
    try {
      const data = await amgApi.post("/debt-solution/get-charges-ci",params);
      return data;
    } catch (error) {
      throw error;
    }
  }
 
  async updateMountChargeCI(params) {
    try {
      const data = await amgApi.post("/debt-solution/update-charges-ci",params);
      return data;
    } catch (error) {
      throw error;
    }
  }

  async getTrackingChargesCI(params) {
    try{
      const data = await amgApi.post("/debt-solution/get-tracking-charges-ci",params);
      return data;
    }catch(error){
      throw error;
    }
  }
}

export default new chargesService();
