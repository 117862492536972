<template>
  <b-modal
    ref="modal-tracking"
    title="TRACKING"
    title-tag="h3"
    size="lg"
    modal-class="modal-primary"
    hide-footer
    @hidden="$emit('close')"
  >
    <b-table
      ref="trackingTable"
      class="blue-scrollbar"
      primary-key="id"
      empty-text="No matching records found"
      responsive="md"
      sticky-header="50vh"
      small
      show-empty
      no-provider-filtering
      :fields="fields"
      :items="myProvider"
      :busy.sync="isBusy"
    >
      <template #table-busy>
        <div class="text-center text-primary my-2">
          <b-spinner class="align-middle mr-1" />
          <strong>Loading ...</strong>
        </div>
      </template>
      <template #cell(old_value)="data">
        <span> $ {{ data.item.old_value }}</span>
      </template>
      <template #cell(new_value)="data">
        <span> $ {{ data.item.new_value }}</span>
      </template>
      <template #cell(status)="data">
        <b-badge variant="light-success" class="text-capitalize">
          {{ data.item.status }}
        </b-badge>
      </template>
      <template #cell(updated_at)="data">
        <span v-if="data.item.updated_at">{{ data.item.updated_at }}</span>
        <span v-else> - </span>
      </template>
    </b-table>
  </b-modal>
</template>
  <script>
import ChargesService from "@/views/debt-solution/views/settings/views/message-cost-config/service/charges.service.js";
export default {
  props: {
    chargeId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      isBusy: false,
      fields: [
        { key: "old_value", label: "Old amount", sortable: false },
        { key: "new_value", label: "new amount", sortable: false },
        { key: "status", label: "status", sortable: false },
        { key: "created_by", label: "Created By", sortable: false },
        { key: "created_at", label: "Created AT", sortable: false },
        { key: "updated_at", label: "Update AT", sortable: false },
      ],
    };
  },
  mounted() {
    this.toggleModal("modal-tracking");
  },
  methods: {
    async myProvider() {
      try {
        this.addPreloader();
        const { data } = await ChargesService.getTrackingChargesCI({
          id: this.chargeId,
        });
        return data;
      } catch (error) {
        this.showErrorSwal(error);
      } finally {
        this.removePreloader();
      }
    },
  },
};
</script>