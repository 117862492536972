<template>
  <div class="ci_charges">
    <b-card>
      <validation-observer ref="form">
        <b-row>
          <b-col class="text-center d-flex justify-content-between" cols="12">
            <h2 class="mb-0">COST</h2>
          </b-col>
        </b-row>
        <!-- <b-row class="mt-1">
          <b-col
            v-for="(commissionData, index) in commissionsData"
            :key="index"
            md="6"
          >
            <b-form-group
              v-if="!isBusy"
              label-class="font-bureau-style font-weight-normal color-gray-input-sm"
              label-cols-lg="3"
              label-cols-md="4"
              label-cols-sm="3"
              content-cols-lg="8"
              content-cols-md="7"
              content-cols-sm="9"
            >
              <template #label>
                <feather-icon
                  icon="SquareIcon"
                  style="margin-right: 0.5rem"
                  :class="
                    commissionData.category === 1 ||
                    commissionData.category === 4
                      ? 'text-primary'
                      : 'text-warning'
                  "
                  :style="
                    commissionData.category === 1 ||
                    commissionData.category === 4
                      ? 'fill: #0090e7'
                      : 'fill: #ff9f43'
                  "
                />
                <span> {{ commissionData.description.toUpperCase() }} </span>
              </template>

              <div
                class="w-100"
                :class="
                  commissionData.category == 2
                    ? 'd-flex justify-content-between'
                    : ''
                "
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="commissionData.description + index"
                  rules="required|validate-amount-zero"
                >
                  <b-input-group>
                    <money
                      v-model="commissionData.amount"
                      v-bind="vMoneyDollars"
                      class="form-control input-form"
                      :disabled="!commissionData.editingAmount"
                    />
                    <b-input-group-append
                      v-if="commissionData.editingAmount"
                      class="border-right"
                    >
                      <b-button
                        variant="outline-primary"
                        class="btn-icon"
                        @click="saveMinAmountChange(commissionData)"
                      >
                        <feather-icon icon="SaveIcon" class="cursor-pointer" />
                      </b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                      <b-button
                        v-if="!commissionData.editingAmount"
                        variant="outline-warning"
                        class="btn-icon"
                        @click="enableEditMinAmount(index)"
                      >
                        <feather-icon icon="Edit2Icon" class="cursor-pointer" />
                      </b-button>
                      <b-button
                        v-if="commissionData.editingAmount"
                        variant="outline-danger"
                        class="btn-icon"
                        @click="cancelEditMinAmount(index)"
                      >
                        <feather-icon icon="XIcon" class="cursor-pointer" />
                      </b-button>
                      <b-button
                        v-if="!commissionData.editingAmount"
                        variant="outline-info"
                        class="btn-icon"
                        @click="openTrackingCharges(commissionData.id)"
                      >
                        <feather-icon icon="ListIcon" class="cursor-pointer" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <span v-if="errors[0]" class="text-danger">{{
                    errors[0]
                  }}</span>
                </validation-provider>
              </div>
            </b-form-group>
            <div v-if="isBusy" class="d-flex mb-1">
              <b-skeleton width="20%" style="margin-right: 3.4rem" />
              <b-skeleton type="input" width="66%" />
            </div>
          </b-col>
        </b-row> -->

        <b-row class="mt-1">
          <b-col md="6">
            <b-form-group
              v-if="!isBusy"
              label-class="font-bureau-style font-weight-normal color-gray-input-sm"
              label-cols-lg="5"
              label-cols-md="12"
              label-cols-sm="12"
              content-cols-lg="5"
              content-cols-md="12"
              content-cols-sm="12"
            >
              <template #label>
                <feather-icon
                  icon="SquareIcon"
                  class="text-warning"
                  style="margin-right: 0.5rem; fill: #ff9f43"
                />
                <span>
                  1ST INSTALLMENT PERCENT
                  <span class="text-primary"> (BL)</span></span
                >
              </template>

              <div class="w-100">
                <validation-provider v-slot="{ errors }" rules="required">
                  <b-input-group>
                    <!-- prepend -->
                    <b-input-group-prepend>
                      <b-input-group-text>%</b-input-group-text>
                    </b-input-group-prepend>
                    <b-form-input
                      v-model="initialCostPercent"
                      v-mask="'##.##'"
                      :disabled="!editinitialCostPercent"
                    ></b-form-input>
                    <b-input-group-append>
                      <b-button
                        v-if="!editinitialCostPercent"
                        variant="outline-warning"
                        class="btn-icon"
                        @click="editinitialCostPercent = true"
                      >
                        <feather-icon icon="Edit2Icon" class="cursor-pointer" />
                      </b-button>

                      <b-button
                        variant="outline-primary"
                        class="btn-icon"
                        v-if="editinitialCostPercent"
                        @click="updateCiGeneralSettings"
                      >
                        <feather-icon icon="SaveIcon" class="cursor-pointer" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <span v-if="errors[0]" class="text-danger">{{
                    errors[0]
                  }}</span>
                </validation-provider>
              </div>
            </b-form-group>
            <div v-if="isBusy" class="d-flex mb-1">
              <b-skeleton width="20%" style="margin-right: 3.4rem" />
              <b-skeleton type="input" width="66%" />
            </div>
          </b-col>

          <b-col md="6">
            <b-form-group
              v-if="!isBusy"
              label-class="font-bureau-style font-weight-normal color-gray-input-sm"
              label-cols-lg="5"
              label-cols-md="12"
              label-cols-sm="12"
              content-cols-lg="5"
              content-cols-md="12"
              content-cols-sm="12"
            >
              <template #label>
                <feather-icon
                  icon="SquareIcon"
                  class="text-warning"
                  style="margin-right: 0.5rem; fill: #ff9f43"
                />
                <span> INVESTIGATION COST </span>
              </template>

              <div class="w-100">
                <validation-provider v-slot="{ errors }" rules="required">
                  <b-input-group>
                    <money
                      v-model="investigationCost"
                      v-bind="vMoneyDollars"
                      class="form-control input-form"
                      :disabled="!editInvestigationCost"
                    />
                    <b-input-group-append>
                      <b-button
                        v-if="!editInvestigationCost"
                        variant="outline-warning"
                        class="btn-icon"
                        @click="editInvestigationCost = true"
                      >
                        <feather-icon icon="Edit2Icon" class="cursor-pointer" />
                      </b-button>

                      <b-button
                        variant="outline-primary"
                        class="btn-icon"
                        v-if="editInvestigationCost"
                        @click="updateCiGeneralSettings"
                      >
                        <feather-icon icon="SaveIcon" class="cursor-pointer" />
                      </b-button>
                    </b-input-group-append>
                  </b-input-group>
                  <span v-if="errors[0]" class="text-danger">{{
                    errors[0]
                  }}</span>
                </validation-provider>
              </div>
            </b-form-group>
            <div v-if="isBusy" class="d-flex mb-1">
              <b-skeleton width="20%" style="margin-right: 3.4rem" />
              <b-skeleton type="input" width="66%" />
            </div>
          </b-col>
        </b-row>
      </validation-observer>

      <b-row class="mt-1">
        <b-col class="text-center d-flex justify-content-between" cols="12">
          <h2 class="mb-0">AUTOMATIC TASKS</h2>
        </b-col>
      </b-row>

      <b-row class="mt-1">
        <b-col md="6">
          <b-form-group
            v-if="!isBusy"
            label-class="font-bureau-style font-weight-normal color-gray-input-sm"
            label-cols-lg="5"
            label-cols-md="12"
            label-cols-sm="12"
            content-cols-lg="6"
            content-cols-md="12"
            content-cols-sm="12"
          >
            <template #label>
              <feather-icon
                icon="SquareIcon"
                style="margin-right: 0.5rem; fill: #0090e7"
              />
              <span> DAYS TO SCHEDULE </span>
            </template>

            <div class="w-100">
              <validation-provider v-slot="{ errors }" rules="required">
                <b-input-group>
                  <v-select
                    v-model="automaticScheduleDays"
                    :options="
                      (function () {
                        let options = [];
                        for (let i = 0; i <= 30; i++) {
                          options.push({ label: i, value: i });
                        }
                        return options;
                      })()
                    "
                    class="input-form w-75"
                    :class="errors[0] ? 'is-invalid' : ''"
                    :disabled="!editAutomaticScheduleDays"
                    :reduce="(option) => option.value"
                    :clearable="false"
                  />
                  <b-input-group-append>
                    <b-button
                      v-if="!editAutomaticScheduleDays"
                      variant="outline-warning"
                      class="btn-icon"
                      @click="editAutomaticScheduleDays = true"
                    >
                      <feather-icon icon="Edit2Icon" class="cursor-pointer" />
                    </b-button>

                    <b-button
                      variant="outline-primary"
                      class="btn-icon"
                      v-if="editAutomaticScheduleDays"
                      @click="updateCiGeneralSettings"
                    >
                      <feather-icon icon="SaveIcon" class="cursor-pointer" />
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
                <span v-if="errors[0]" class="text-danger">{{
                  errors[0]
                }}</span>
              </validation-provider>
            </div>
          </b-form-group>
          <div v-if="isBusy" class="d-flex mb-1">
            <b-skeleton width="20%" style="margin-right: 3.4rem" />
            <b-skeleton type="input" width="66%" />
          </div>
        </b-col>
      </b-row>
    </b-card>

    <TrackingChargesModal
      v-if="showTrackingChargesModal"
      :chargeId="chargeId"
      @close="showTrackingChargesModal = false"
    />
  </div>
</template>

<script>
import ChargesService from "@/views/debt-solution/views/settings/views/message-cost-config/service/charges.service.js";
import ListTrackingCedCommissions from "@/views/ce-digital/sub-modules/settings/views/commissions/components/modals/ListTrackingCedCommissions.vue";
import TrackingChargesModal from "@/views/debt-solution/views/settings/views/message-cost-config/modal/TrackingChargesModal.vue";
import { mapGetters } from "vuex";
import CourtInfoService from "@/views/debt-solution/views/court-info/services/court-info.service";

export default {
  components: {
    ListTrackingCedCommissions,
    TrackingChargesModal,
  },
  data() {
    return {
      vMoneyDollars: {
        decimal: ".",
        thousand: ",",
        precision: 2,
        prefix: "$ ",
        min: 0.0,
        maxlength: 10,
      },
      commissionsData: [
        // typeCommission: 1 amount 2 percentage, category: 1 action 2 charge
      ],
      oldValue: 0,
      isBusy: false,
      showTrackingChargesModal: false,
      chargeId: null,

      editAutomaticScheduleDays: false,
      editinitialCostPercent: false,
      editInvestigationCost: false,
      editd: false,
      automaticScheduleDays: 0,
      initialCostPercent: 0,
      investigationCost: "0.00",
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
    moduleIdNum() {
      return this.currentUser.modul_id;
    },
  },
  async created() {
    await this.getCommissionData();
    await this.getCiGeneralSettings();
  },
  methods: {
    enableEditMinAmount(index) {
      this.commissionsData[index].savedData =
        this.commissionsData[index].amount;
      this.commissionsData[index].editingAmount = true;
      this.oldValue = this.commissionsData[index].amount;
    },
    cancelEditMinAmount(index) {
      this.commissionsData[index].amount =
        this.commissionsData[index].savedData;
      this.commissionsData[index].editingAmount = false;
      this.oldValue = this.commissionsData[index].amount;
    },
    async saveMinAmountChange(commissionData) {
      try {
        const result = await this.$refs.form.validate();
        if (result) {
          const response = await this.showConfirmSwal();
          if (response.isConfirmed) {
            const data = await ChargesService.updateMountChargeCI({
              amount: commissionData.amount,
              id: commissionData.id,
              oldAmount: this.oldValue,
              user_id: this.currentUser.user_id,
            });
            if (data.status === 200) {
              await this.getCommissionData();
            }
          }
        }
      } catch (e) {
        this.showErrorSwal(e);
        console.log("ERRORS ON CommissionsPerModule : updateMountCharge");
      }
    },
    async reloadPage() {
      await this.getCommissionData();
    },
    async getCommissionData() {
      try {
        let fake = [];
        this.commissionsData = [];
        this.isBusy = true;
        const data = await ChargesService.getChargesCI();
        if (data.status === 200) {
          fake = data.data;
          fake.forEach((commission) => {
            commission.editingAmount = false;
            commission.editingValue = false;
            commission.savedData = 0;
            commission.value = parseFloat(commission.value);
          });
          this.commissionsData = fake;
          setTimeout(() => {
            this.isBusy = false;
          }, 900);
        }
      } catch (e) {
        this.removePreloader();
        this.showErrorSwal(e);
        this.isBusy = false;
      }
    },

    async getCiGeneralSettings() {
      try {
        const { data, status } = await CourtInfoService.getCiGeneralSettings();
        if (status === 200) {
          // this.automaticScheduleDays =
          //    data[0].days : this.automaticScheduleDays;
          // this.initialCostPercent =
          //   data.length > 0
          //     ? data[0].initial_cost_percent
          //     : this.initialCostPercent;
          // this.investigationCost =
          //   data.length > 0
          //     ? data[0].investigation_cost
          //     : this.investigationCost;

          this.automaticScheduleDays = data[0].days ?? 0;
          this.initialCostPercent = data[0].initial_cost_percent ?? "0.00";
          this.investigationCost = data[0].investigation_cost ?? "0.00";
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },

    async updateCiGeneralSettings() {
      try {
        const data = await CourtInfoService.updateCiGeneralSettings({
          days: this.automaticScheduleDays,
          initial_cost_percent: this.initialCostPercent,
          investigation_cost: this.investigationCost,
        });
        if (data.status === 200) {
          this.editAutomaticScheduleDays = false;
          this.editinitialCostPercent = false;
          this.editInvestigationCost = false;
          this.showToast(
            "success",
            "top-right",
            "Success",
            "CheckIcon",
            "Automatic task settings updated successfully"
          );
        }
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    openTrackingCharges(id) {
      this.showTrackingChargesModal = true;
      this.chargeId = id;
      console.log("id", this.chargeId);
    },
  },
};
</script>

<style lang="scss">
.ci_charges {
  .w-50 {
    width: 52% !important;
  }
  .tasks {
    span.k-widget.k-timepicker.rounded.bg-transparent.k-picker-custom.picker-select-date {
      width: 52% !important;
    }
  }
}
</style>
